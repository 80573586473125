/* FIXES
--------------------------------------------------------------------------------------------*/

// TOPTASK
.ac_toptasks_item_icon svg {
    fill: $brand-primary;
    stroke: $brand-secondary;
}

.ac_toptasks_item_image-container {
    background: $brand-secondary;
}

.ac_toptasks_item_container {
    height: 120px;
}

.ac_toptasks_item_image {
    display: block;
    width: 114px;
    height: 114px; 
    margin: 3px;
}

.ac_toptasks_item_content_title {
    padding-left: 120px;
}

//FORMS

input[type="radio" i] {
    padding:0 !important;
    margin:0 !important;
}

.gform_wrapper ul.gfield_checkbox li, .gform_wrapper ul.gfield_radio li {
    padding:0 !important;
    margin:0 !important;
    margin-right:10px !important;
    display:inline;
}

.gform_wrapper ul.gfield_checkbox, .gform_wrapper ul.gfield_radio {
    margin-top:-26px !important;
}

[data-s-type="hero"] .ac_hero_container {
    height: 75vh !important;
}

//HEADERS
[data-s-type="hero-alt"] {
    position: relative;
    background: linear-gradient(
-135deg
, #0d2448, #184589) !important;
    background-image: linear-gradient(
-135deg
, rgb(13, 36, 72), rgb(24, 69, 137)) !important;
    background-position-x: initial !important;
    background-position-y: initial !important;
    background-size: initial !important;
    background-repeat-x: initial !important;
    background-repeat-y: initial !important;
    background-attachment: initial !important;
    background-origin: initial !important;
    background-clip: initial !important;
    background-color: initial !important;

}

.ac_menu-2_logo_container{
        img {
        margin-top:-15px !important;
        max-width: 135% !important;
    }
}

.ac_menu-2_logo_img {
    width: 190px;
}

.ac_menu-2_sticky.is-stuck .ac_menu-2_logo_img {
    width: 140px;
    margin-top:-5px !important;
}

.ac_menu-2_bar_top {
    background: none;
    a {
        color:$brand-secondary !important;
    }
}

@media screen and (max-width: 1100px){
    [data-s-type="hero"] .ac_hero_container {
        height: 60vh !important;
    }
    
}

[data-s-type="hero"] .ac_hero_slide_content_text {
    color: #ffffff;
    font-size: 20px;
    padding: 18px 30px;
    background-color: rgba(38, 35, 51, 0.7);
}

[data-s-type="hero"] .ac_hero_slide_content_inner {
    justify-content: flex-start;
}

.ac_menu-2_cta_link {
    font-size: 20px !important;
}

[data-m-type="menu-2"] {
    a {
        color:$brand-black;
        font-weight: 600;
        font-size: 13px;
    }
}

[data-s-type="hero-alt"] .ac_hero_alt_container {
    height:200px
}

#breadcrumbs {
    display: none;
}

// MENU MOBILE
@media screen and (max-width: 49.9375em){
    .ac_menu-2_sticky{
        display: block;
    }

    [data-s-type="hero"] .ac_hero_container {
        height: 50vh !important;
    }
    

    #menu-mobile{
        list-style: none;
        width: 100%;
        margin-left: 0;
        padding-left: 0;
        li{
            display: block;
            width: 100%;
            text-align: center;
            margin: 0 0 5px 0;
            padding: 0 0 5px 0;
            border-bottom: 1px solid rgba(0,0,0,.1);
    
            a{
                text-align: center;
                display: block;
            }

            &:last-of-type{
                border-bottom: 0;
            }
        }

        .sub-menu{
            background: rgba(0,0,0,.1);
            padding: 10px 0;
            border-radius: 5px;
            li{
                border-bottom: 0;
            }
        }
    }
}

h2, h3, h4 {
    color: $brand-primary;
    padding-bottom:10px;
}

// GENERAL
.main-container [data-s-type="section-content"] a {
    text-decoration: underline;
    color: $brand-primary;
    &.button {
        color: $brand-white;
        text-decoration: none;
    }
}

.button {
    background: linear-gradient(
        -90deg, #2ea3f2, #143a73);
}

body {
    color:$brand-black;
}

//NEWS

[data-s-id="home-news"] .ac_item_image-container {
    display: none;
}
[data-s-type="news"] .ac_item_content_title {
    color: $brand-black;
}


// BRANDS

.ac_brands_item_container {
    border: none !important;
}

.ac_brands_item {
    border: none !important;
}

// SERVICES
[data-s-id="home-services"] {
    padding-bottom: 0px;
}

[data-s-type="services"] .ac_item_content_icon_container {
    display: none;
}

//FOOTER
[data-f-type="footer-1"] h2 {
    font-size: 160%;
    margin-bottom: 0px;
    opacity: .5;
}

[data-f-type="footer-1"] .ac_footer_primary {
    background: $brand-black;
}

[data-f-type="footer-1"] .ac_footer_primary_column:nth-child(4) {
    margin-left: -230px;
}

[data-f-type="footer-1"] .ac_footer_primary_container {
    padding: 20px 10px 5px 10px;
}


//CTA
.ac_cta_column {
    p {
        font-size: 18px;
        font-weight: 600;
    }
}

// IMAGES OVERLAY
[data-s-type="image-gallery_full-screen"] .slick-next svg, [data-s-type="image-gallery_full-screen"] .slick-prev svg {
    fill: $brand-primary;
}

li::marker {
    color:$brand-primary;
}

.ac_content_1-1 {
    padding-left: 10px;
    padding-right: 10px;
}

@media screen and ( max-width: 640px ){
    [data-s-type="quotes"] .ac_item{

        .ac_item_content_copy{
            padding: 10px 40px;
        }

        .ac_item_content_title {
            color: white;
        }

        .ac_item_content_title_quote-left{
            left: -3em;
        }

        .ac_item_content_title_quote-right{
            right: -3em;
        }

        .ac_item_content_copy-below{
            display: none;
        }
    }
}
@media screen and ( max-width: 800px ){

    [data-f-type="footer-1"] .ac_footer_primary_column:nth-child(4) {
        margin-left: -12px;
    }
}


@media screen and ( max-width: 500px ){
    [data-s-type="quotes"] .ac_item{
        padding: 1.5em 20px;
    }
}